import * as React from 'react';

export default function TechSetup(): React.ReactNode {
	return (
		<>
			<h4>Вот лишь некоторые из наших услуг в списке:</h4>

			<ul>
				<li>Диагностика, замена комплектующих (например видеокарты, материнской платы, звуковой карты, жесткого диска) и экстренный ремонт компьютеров</li>
				<li>Установка антивируса и удаление вредоносных программ (вирусов, сетевых червей, троянских программ)</li>
				<li>Модернизация оборудования с установкой нового лицензионного программного обеспечения</li>
				<li>Установка и обновление Windows и программного обеспечения</li>
				<li>Настройка интернета</li>
				<li>Восстановление потерянных данных</li>
				<li>Сборка компьютеров на заказ под специальные задачи клиента</li>
				<li>Обновление (апгрейд) компьютерной техники</li>
				<li>Консультации</li>
			</ul>
		</>
	);
}
