import * as React from 'react';

export default function Network (): React.ReactNode {
	return (
		<>
			<h5>Мы с высокой долей профессионализма готовы предоставить следующие услуги:</h5>

			<ul>
				<li>Монтаж / демонтаж сетевого оборудования</li>
				<li>Настройка программного обеспечения и функций сетевого оборудования</li>
				<li>Обеспечение отказоустойчивого и высокопроизводительного доступа в Интернет</li>
				<li>Ограничение доступа к ресурсам сети Интернет</li>
				<li>Обеспечение безопасного удаленного доступа</li>
				<li>Определение и настройка политик безопасности, в том числе безопасность Wi-Fi</li>
				<li>Управление правами доступа</li>
				<li>Профилактическое обслуживание / установка обновлений программного обеспечения</li>
				<li>Восстановление работоспособности</li>
				<li>Вывод из эксплуатации устаревшего оборудования / программного обеспечения</li>
				<li>Консультации</li>
			</ul>

			<blockquote>
				Чтобы не теряться в хитросплетениях сетей не нужно быть пауком. Достаточно просто обладать огромным опытом и знаниями тонкостей коммуникации машин друг с другом.<br/>
				Мы можем похвастаться и тем, и другим!
			</blockquote>
		</>
	)
}
